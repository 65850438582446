import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

import { useTranslation } from "react-i18next";

import projectList from "../../../assets/json/projects.json"

const item = projectList.investment;

function Investment() {
  const { t } = useTranslation();
  return (
    <>
      <div className="section section-aboutus-project">
        <Container>
          <Row sm="12" md="12">
            <Col className="ml-auto" sm="12" md="3">
              <h4 className="npy-red">PROJECT 4</h4>
              <h2><strong>{t('aboutus.project.investment.title')}</strong></h2>
            </Col>
            <Col className="mr-auto" sm="12" md="7">
              <h5>{t('aboutus.project.investment.desc')}</h5>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="10">
            <img alt="..." src={require("assets/img"+item.src).default}/>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Investment;
