import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Card,
  Row,
  Col
} from "reactstrap";

import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from "react-i18next";

function Faq({chain}) {
  const { t } = useTranslation();
  const [faqOpen, setFaqOpen] = React.useState(-1);
  const faqList = t('network.chain.'+chain.name.toLowerCase()+'.faq', { returnObjects: true });

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  return (
    <>
      <div data-background-color="lightgray">
          <Container className="section-chain-faq">
            <h3><strong><center>{t('network.chain.faq.title')}</center></strong></h3>
            {faqList.map((faq, index) => {
              return (
                <Card href="#page-top" key={index}>
                  <button
                    className="btn-toggle btn-faq"
                    onClick={() => { faqOpen === index ? setFaqOpen(-1) : setFaqOpen(index); }}>
                    <h5><strong>{faq.question}</strong></h5>
                    <FaChevronDown />
                  </button>
                  <div className="faq-toggle" style={faqOpen === index ? { display: "block" } : { display: 'none' }}>
                    <h5>{faq.answer}</h5>
                  </div>
                </Card>
              );
            })}

            <hr/>
            <Row sm="12" md="12" style={{ justifyContent: "space-between" }}>
              <Col sm="12" md="9">
                <h4 className="chain-contact-text">{t('network.chain.contact.title')}</h4>
              </Col>
              <Col sm="12" md="3">
                <Button className="btn-contact" href="mailto:co_staking@neoply.com">
                  <h4>{t('network.chain.contact.btn')}&nbsp;&nbsp;&nbsp;<FaChevronRight/></h4>
                </Button>
              </Col>
            </Row>
          </Container>
      </div>
    </>
  );
}

export default Faq;
