import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "lang/i18n.js";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
// pages for this kit
import Home from "pages/home";
import Network from "pages/network";
import AboutUs from "pages/aboutus";
import Contact from "pages/contact";

import './config';
import { Modal } from "components/Modal";

// import ReactGA from 'react-ga';
// ReactGA.initialize('G-DQLX0G9VW9');
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/index" render={(props) => <Home {...props} />} />
        <Route
          exact path="/network/:id"
          render={(props) => <Network {...props} />}
        />
        <Route
          path="/network"
          render={(props) => <Network {...props} />}
        />
        <Route
          path="/aboutus"
          render={(props) => <AboutUs {...props} />}
        />
        <Route
          path="/contact"
          render={(props) => <Contact {...props} />}
        />
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
    {/* 팝업 띄워야 할 일 있을때 활성화 후 내용 바꾸기. */}
    {/* <Modal /> */}
  </BrowserRouter>,
  document.getElementById("root")
);
