import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

import { useTranslation } from "react-i18next";

function Feature() {
  const { t } = useTranslation();
  return (
    <>
      <div className="section-feature">
        <Container>
          <br/>
          <Row className="container-fluid ml-0" sm="12">
            <Col className="d-flex" sm="4">
              <Card className="card-aboutus-feature">
                <CardHeader>
                  <img
                    alt="..."
                    src={require("assets/img/aboutus/feature_1.jpg").default}
                  />
                </CardHeader>
                <CardBody>
                  <h4><strong>{t('aboutus.feature.0.title')}</strong></h4>
                  <p className="npy-gray" style={{ fontSize: "1.1em" }}>
                    {t('aboutus.feature.0.desc')}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col className="d-flex" sm="4">
              <Card className="card-aboutus-feature">
                <CardHeader>
                  <img
                    alt="..."
                    src={require("assets/img/aboutus/feature_2.jpg").default}
                  />
                </CardHeader>
                <CardBody>
                  <h4><strong>{t('aboutus.feature.1.title')}</strong></h4>
                  <p className="npy-gray" style={{ fontSize: "1.1em" }}>
                    {t('aboutus.feature.1.desc')}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col className="d-flex" sm="4">
              <Card className="card-aboutus-feature">
                <CardHeader>
                  <img
                    alt="..."
                    src={require("assets/img/aboutus/feature_3.jpg").default}
                  />
                </CardHeader>
                <CardBody>
                  <h4><strong>{t('aboutus.feature.2.title')}</strong></h4>
                  <p className="npy-gray" style={{ fontSize: "1.1em" }}>
                    {t('aboutus.feature.2.desc')}
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Feature;
