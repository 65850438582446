import React from "react";
import { Redirect } from "react-router";

// core components
import Navigator from "components/common/Navigator.js";
import Header from "components/network/Header.js";
import List from "components/network/List.js";
import Feature from "components/network/Feature.js";
import Contact from "components/common/Contact.js";
import Footer from "components/common/Footer.js";

import Chain from "pages/network/chain";

import chainList from "../../assets/json/chains.json"

function Network({match}) {
  const name = match.params.id;
  const chain = chainList.find((e) => e.name.toLowerCase() === name && e.type === window.CONFIG.DELEGATE);
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  if(typeof chain !== 'undefined'){
    const others = chainList.filter((e) => e.name.toLowerCase() !== name && e.type === window.CONFIG.DELEGATE);
    return <> <Chain chain={chain} others={others}/> </>;
  }

  if(typeof name !== 'undefined') {
    match.params.id = undefined;
    return (
      <>
        <Redirect to="/network" />
      </>
    );
  }

  return (
    <>
      <Navigator />
      <div className="wrapper">
        <Header />
        <List />
        <Feature />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default Network;
