import React from "react";

// reactstrap components
import { Row, Col, Container } from "reactstrap";

import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation();
  return (
    <>
      <div className="page-header page-header-small network-banner">
        <div className="page-header-image"></div>
        <Container>
          <Row className="" xs="12" sm="12">
              <Col className="network-banner-text" xs="12" sm="6">
                <h1><strong>{t('network.title')}</strong></h1>
                <h4 className="npy-gray">{t('network.desc')}</h4>
              </Col>
              <Col className="network-banner-img" xs="12" sm="6">
                <img
                    alt="..."
                    src={require("assets/img/network/banner.png").default}
                />
              </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Header;
