import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

import { useTranslation } from "react-i18next";

import chainList from "../../assets/json/chains.json";

function List() {
  const { t } = useTranslation();
  return (
    <>
      <div className="section section-network" data-background-color="lightgray">
        <Container>
          {/* Delegates */}
          <div>
            <h3 className="npy-black"><strong>Delegates</strong></h3>
            <h5 className="npy-gray">{t('network.delegate')}</h5>
            <Row className="justify-content-center" sm="12" md="12" lg="3">
              {chainList.map((chain, index) =>
                chain.type === window.CONFIG.DELEGATE ?
                  <Col sm="5" md="6" lg="3" key={index}>
                    <Card className="card-network" tag="a" href={"/network/"+chain.name.toLowerCase()}>
                      <CardBody className="card-item-center">
                        <h5>{chain.name}</h5>
                        <div className="card-logo">
                          <img alt="..." src={require("assets/img"+chain.logo).default} />
                        </div>
                        <h6 className="npy-gray">{t('network.card.desc')}</h6>
                        <h4>~{chain.max_roi}%</h4>
                      </CardBody>
                      <CardHeader>
                        <h5>{t('network.card.btn_delegate')}</h5>
                      </CardHeader>
                    </Card>
                  </Col>
                  : null
              )}
            </Row>
          </div>
          {/* Node Only */}
          <div className="div-list">
            <h3 className="npy-black"><strong>Nodes Only</strong></h3>
            <h5 className="npy-gray">{t('network.node_only')}</h5>
            <Row className="justify-content-center" sm="12" md="12" lg="3">
              {chainList.map((chain, index) =>
                chain.type === window.CONFIG.NODE_ONLY ?
                  <Col sm="5" md="6" lg="3" key={index}>
                    <Card className="card-network node-only" tag="a" href={chain.link} target="_blank">
                      <CardBody className="card-item-center">
                        <h5>{chain.name}</h5>
                        <div className="card-logo">
                          <img alt="..." src={require("assets/img"+chain.logo).default} />
                        </div>
                        <h5  className="npy-gray">{chain.net_id}</h5>
                      </CardBody>
                      <CardHeader>
                        <h5>{t('network.card.btn_view')}</h5>
                      </CardHeader>
                    </Card>
                  </Col>
                  : null
              )}
            </Row>
          </div>
          {/* Coming Soon */}
          <div className="div-list">
            <h3 className="npy-black"><strong>Updates coming soon</strong></h3>
            <Row className="justify-content-center" sm="12" md="12" lg="3">
              {chainList.map((chain, index) =>
                chain.type === window.CONFIG.COMING_SOON ?
                  <Col sm="5" md="6" lg="3" key={index}>
                    <Card className="card-network coming-soon" tag="a" href={"/network/"+chain.name}>
                      <CardBody className="card-item-center">
                        <h5>{chain.name}</h5>
                        <div className="card-logo">
                          <img alt="..." src={require("assets/img"+chain.logo).default} />
                        </div>
                        <h5  className="npy-gray">{chain.net_id}</h5>
                      </CardBody>
                      <CardHeader>
                        <h5>{t('network.card.btn_delegate')}</h5>
                      </CardHeader>
                    </Card>
                  </Col>
                  : null
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default List;
