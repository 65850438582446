import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

import { BsList } from 'react-icons/bs';
import { useTranslation } from "react-i18next";

function Navigator() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const { t, i18n } = useTranslation();

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top nav-black" expand="md" color="info">
        <Container>
          <Row style={{ width: "100%", justifyContent: "space-between" }} sm="12">
            <Col className="nav-logo" style={{ width: "50%" }} sm="4">
              <div className="navbar-translate">
                <NavbarBrand to="/index" tag={Link}>
                  <img
                    alt="..."
                    src={require("assets/img/common/nav_logo.png").default}
                  />
                </NavbarBrand>
                
              </div>
            </Col>
            <Col className={collapseOpen ? "nav-col-open" : ""} style={{ width:"50%", textAlign: "right", paddingRight: "0px" }} sm="8">
              <button
                className="navbar-toggler navbar-toggler-right"
                onClick={() => {
                  document.documentElement.classList.toggle("nav-open");
                  setCollapseOpen(!collapseOpen);
                }}
                aria-expanded={collapseOpen}
                type="button"
              >
                <BsList/>
              </button>
              <Collapse
                className="justify-content-end"
                isOpen={collapseOpen}
                navbar>
                <Nav navbar>
                  <NavItem>
                    <NavLink to="/index" tag={Link} onClick={(e) => setCollapseOpen(false)}>
                      <p>{t('nav_menu.home')}</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/network" tag={Link} onClick={(e) => setCollapseOpen(false)}>
                      <p>{t('nav_menu.network')}</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/aboutus" tag={Link} onClick={(e) => setCollapseOpen(false)}>
                      <p>{t('nav_menu.aboutus')}</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/contact" tag={Link} onClick={(e) => setCollapseOpen(false)}>
                      <p>{t('nav_menu.contact')}</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <Button
                      style={{ display: i18n.language === "ko-KR" || i18n.language === "ko" ? "none" : "block"}}
                      className="btn-lang"
                      onClick={(e) => {
                        i18n.changeLanguage("ko-KR");
                        setCollapseOpen(false);
                        sessionStorage.setItem("language", "ko-KR");
                      }}
                    >
                      KOR
                    </Button>
                    <Button
                      style={{ display: i18n.language === "en-US" || i18n.language === "en" ? "none" : "block"}}
                      className="btn-lang"
                      onClick={(e) => {
                        i18n.changeLanguage("en-US");
                        setCollapseOpen(false);
                        sessionStorage.setItem("language", "en-US");
                      }}
                    >
                      ENG
                    </Button>
                  </NavItem>
                </Nav>
              </Collapse>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigator;
