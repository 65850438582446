import React from "react";

// core components
import Navigator from "components/common/Navigator.js";
import Footer from "components/common/Footer.js";

import Header from "components/network/chain/Header.js"
import Guide from "components/network/chain/Guide.js"
import Faq from "components/network/chain/Faq.js"

function Chain({chain, others}) {
  return (
    <>
      <Navigator />
      <div className="wrapper">
        <Header chain={chain} others={others}/>
        <div className="section-chain">
          <Guide chain={chain}/>
          <Faq chain={chain}/>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Chain;
