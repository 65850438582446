import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Button
} from "reactstrap";

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { AiOutlineArrowRight } from 'react-icons/ai';

import { useTranslation } from "react-i18next";

import projectList from "../../../assets/json/projects.json"

const items = projectList.eoseoul;

function EOSeoul() {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section section-aboutus-project" data-background-color="lightgray">
        <Container>
          <Row sm="12" md="12">
            <Col className="ml-auto" sm="12" md="3">
              <h4 className="npy-red">PROJECT 1</h4>
              <h2><strong>{t('aboutus.project.eoseoul.title')}</strong></h2>
            </Col>
            <Col className="mr-auto" sm="12" md="7">
              <h5>{t('aboutus.project.eoseoul.desc')}</h5>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="10">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map((item, index) => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={index}
                    >
                      <img alt="..." src={require("assets/img"+item.src).default}/>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <FaChevronLeft/>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <FaChevronRight/>
                </a>
              </Carousel>
            </Col>
          </Row>
          <Row md="10">
            <Col className="mr-auto ml-auto" md="10">
              {items.map((item, index) => {
                return (
                  index === activeIndex ?
                    <div key={index}>
                      <div style={{ display: "flex", justifyContent: "space-between", flexDirection:"row" }}>
                        <h4><strong>{t('aboutus.project.eoseoul.card.' + index + '.title')}</strong></h4>
                        {
                          item.link?
                            <Button 
                              className="btn-aboutus-more"
                              href={item.link}
                              target="_blank"
                            >
                              <h6>{t('aboutus.project.eoseoul.card.' + index + '.btn')}&nbsp;&nbsp;&nbsp;<AiOutlineArrowRight/></h6>
                            </Button>
                          : null
                        } 
                      </div>
                      <h5 className="npy-gray">{t('aboutus.project.eoseoul.card.' + index + '.desc')}</h5>
                    </div>
                    : null
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default EOSeoul;
