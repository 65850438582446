import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap";

import { FaArrowLeft, FaArrowRight, FaChevronDown } from 'react-icons/fa';
import { MdContentCopy } from "react-icons/md";

import { useTranslation } from "react-i18next";

function Header({chain, others}) {
  const { t, i18n } = useTranslation();

  const [copied, setCopied] = React.useState(false);

  function copyAddress() {    
    var selectText = document.getElementById('address').innerHTML;
    var input = document.createElement('input');
    input.value = selectText;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    setCopied(true);
    setTimeout(() => {setCopied(false);}, 1500); 
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <>
      <div className="chain-banner">
        <div className="chain-banner-back"></div>
        <div className="chain-banner-front">
        <Row className="nav-chain" xs="12" sm="10" md="12" xl="12">
          <Col xs="5" sm="5" md="6" xl="6" style={{ paddingLeft: "0px" }}>
          <Button className="btn-back" href="/network">
            <h5><FaArrowLeft style={{ marginBottom: "3px" }} />&nbsp;&nbsp;Back to List</h5>
          </Button>
          </Col>
          <Col xs="6" sm="4" md="3" xl="2">
            <UncontrolledDropdown>
              <DropdownToggle
                caret
                data-toggle="dropdown"
                href="#pablo"
                id="navbarDropdown"
                onClick={(e) => e.preventDefault()}
              >
                <h6>{chain.name}</h6>
                <FaChevronDown />
              </DropdownToggle>
              <DropdownMenu aria-labelledby="navbarDropdown">
                {others.map((e, index) => {
                  return (
                    <DropdownItem href={"/network/"+e.name.toLowerCase()} key={index}>
                      <h6>{e.name}</h6>
                    </DropdownItem>
                  )
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        <Row>
          <Card className="card-chain">
            <CardBody>
              <Row sm="12" md="12" style={{ justifyContent: "space-between" }}>
                <Col sm="12" md="6">
                  <img
                    alt="..."
                    src={require("assets/img/network/chain/"+chain.name.toLowerCase()+".png").default}
                  />
                  <h2><strong>{t('network.chain.'+chain.name.toLowerCase()+'.title')}</strong></h2>
                  <h5 className="npy-gray">
                    {t('network.chain.'+chain.name.toLowerCase()+'.desc')}
                  </h5>
                  <Button className="btn-stake" href={i18n.language === "ko-KR" ? chain.staking_link : chain.staking_link_en} target="_blank">
                    <h4>
                      <strong>{t('network.chain.card.btn')}&nbsp;&nbsp;</strong>
                      <FaArrowRight style={{ marginBottom: "3px" }} />
                    </h4>
                  </Button>
                </Col>
                <Col sm="12" md="5">
                  <h5 className="npy-gray">{t('network.chain.card.roi')}</h5>
                  <h3 style={{ color: "#0A9D71" }}><strong>~{chain.max_roi}%</strong></h3>
                  <hr />
                  <Row>
                    <Col style={{paddingLeft : "0px"}}>
                      <h5 className="npy-gray">{t('network.chain.card.lockup')}</h5>
                    </Col>
                    <Col style={{paddingLeft : "0px"}}>
                      <h5 className="npy-gray">{t('network.chain.card.fee')}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{paddingLeft : "0px"}}>
                      <h5><strong>{t('network.chain.'+chain.name.toLowerCase()+'.lockup')}</strong></h5>
                    </Col>
                    <Col style={{paddingLeft : "0px"}}>
                      <h5><strong>{t('network.chain.'+chain.name.toLowerCase()+'.fee')}</strong></h5>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col style={{paddingLeft : "0px"}}>
                      <h5 className="npy-gray">{t('network.chain.card.claim')}</h5>
                    </Col>
                    <Col style={{paddingLeft : "0px"}}>
                      <h5 className="npy-gray">{t('network.chain.card.minimum')}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{paddingLeft : "0px"}}>
                      <h5><strong>{t('network.chain.'+chain.name.toLowerCase()+'.claim')}</strong></h5>
                    </Col>
                    <Col style={{paddingLeft : "0px"}}>
                      <h5><strong>{t('network.chain.'+chain.name.toLowerCase()+'.minimum')}</strong></h5>
                    </Col>
                  </Row>
                  <hr />
                  <h5>
                    {
                        chain.address ? 
                        (
                            <>
                            <strong id="address">{chain.address}&nbsp;</strong> 
                            <MdContentCopy
                              style={{
                                cursor: "pointer"
                              }} 
                              onClick={(e) => {
                                copyAddress();
                              }} 
                            />   
                            {
                                copied ? "copied!" : ""
                            }
                            </>
                        )
                        : <strong>-</strong>
                    }
                  </h5> 
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
        </div>
      </div>
    </>
  );
}

export default Header;
