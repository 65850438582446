import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

import { useTranslation } from "react-i18next";

function AboutUs() {
  const { t } = useTranslation();
  return (
    <>
      <div className="section" data-background-color="lightgray">
        <Container>
          <Row>
            <h4 className="npy-red">About us</h4>
          </Row>
          <Row>
            <h3 className="npy-black"><strong>{t('home.project.title')}</strong></h3>
          </Row>
          <Row className="row-home-aboutus-desc">
            <p>
              {t('home.project.desc')}
            </p>
          </Row>
          <Row md="12">
            <Col className="d-flex" sm="6" lg="3">
              <Card className="card-project" tag="a" href="https://neopin.io">
                <CardHeader>
                  <img
                    alt="..."
                    src={require("assets/img/home/about_neopin.jpg").default}
                  />
                </CardHeader>
                <CardBody>
                  <h4><strong>{t('home.project.neopin.title')}</strong></h4>
                  <p className="npy-gray">
                    {t('home.project.neopin.desc')}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col className="d-flex" sm="6" lg="3">
              <Card className="card-project" tag="a" href="https://eoseoul.io/">
                <CardHeader>
                  <img
                    alt="..."
                    src={require("assets/img/home/about_eoseoul.jpg").default}
                  />
                </CardHeader>
                <CardBody>
                  <h4><strong>{t('home.project.eoseoul.title')}</strong></h4>
                  <p className="npy-gray">
                    {t('home.project.eoseoul.desc')}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col className="d-flex" sm="6" lg="3">
              <Card className="card-project" tag="a" href="/aboutus#project2">
                <CardHeader>
                  <img
                    alt="..."
                    src={require("assets/img/home/about_node.jpg").default}
                  />
                </CardHeader>
                <CardBody>
                  <h4><strong>{t('home.project.node.title')}</strong></h4>
                  <p className="npy-gray">
                    {t('home.project.node.desc')}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col className="d-flex" sm="6" lg="3">
              <Card className="card-project" tag="a" href="https://nblocks.io">
                <CardHeader>
                  <img
                    alt="..."
                    src={require("assets/img/home/about_nblocks.jpg").default}
                  />
                </CardHeader>
                <CardBody>
                  <h4><strong>{t('home.project.nblocks.title')}</strong></h4>
                  <p className="npy-gray">
                    {t('home.project.nblocks.desc')}
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutUs;
