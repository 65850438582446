import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { useTranslation } from "react-i18next";

import projectList from "../../../assets/json/projects.json"

const items = projectList.node;

function NodeOperation() {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section section-aboutus-project">
        <div className="anchor"  id="project2"/>
        <Container>
        <Row sm="12" md="12">
            <Col className="ml-auto" sm="12" md="3">
              <h4 className="npy-red">PROJECT 2</h4>
              <h2><strong>{t('aboutus.project.node.title')}</strong></h2>
            </Col>
            <Col className="mr-auto" sm="12" md="7">
              <h5>{t('aboutus.project.node.desc')}</h5>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="10">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map((item, index) => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={index}
                    >
                      <img alt="..." src={require("assets/img"+item.src).default}/>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <FaChevronLeft/>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <FaChevronRight/>
                </a>
              </Carousel>
            </Col>
          </Row>
          <Row md="10">
            <Col className="mr-auto ml-auto" md="10">
              {items.map((item, index) => {
                return (
                  index === activeIndex ?
                    <div key={index}>
                      <h4><strong>{t('aboutus.project.node.card.' + index + '.title')}</strong></h4>
                    </div>
                    : null
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default NodeOperation;
