import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

import { HiArrowRight } from 'react-icons/hi';

import { useTranslation } from "react-i18next";

import chainList from "../../assets/json/chains.json";

function Network() {
  const { t } = useTranslation();
  
  return (
    <>
      <div className="section section-network" data-background-color="lightgray">
        <Container>
          <Row>
            <h4 className="npy-red">NETWORK</h4>
          </Row>
          <Row>
            <h3 className="npy-black"><strong>{t('home.network.title')}</strong></h3>
          </Row>
          <Row className="justify-content-center" sm="10" md="12" lg="3">
          {chainList.map((chain, index) =>
              index < 4 ? (
              chain.type === window.CONFIG.DELEGATE ?
                <Col sm="5" md="6" lg="3" key={index}>
                  <Card className="card-network" tag="a" href={"/network/"+chain.name.toLowerCase()}>
                    <CardBody className="card-item-center">
                      <h5>{chain.name}</h5>
                      <div className="card-logo">
                        <img alt="..." src={require("assets/img"+chain.logo).default} />
                      </div>
                      <h6 className="npy-gray">{t('home.network.card.desc')}</h6>
                      <h4>~{chain.max_roi}%</h4>
                    </CardBody>
                    <CardHeader>
                      <h5>{t('home.network.card.btn')}</h5>
                    </CardHeader>
                  </Card>
                </Col>
                : null
              ) : ( null )
            )}
          </Row>
          <Row>
            <Card className="card-more" tag="a" href="/network">
              <h5>{t('home.network.more')} <HiArrowRight style={{ marginBottom: "3px" }} /></h5>
            </Card>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Network;
