import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

import { useTranslation } from "react-i18next";

function Feature() {
  const { t } = useTranslation();
  return (
    <>
      <div className="section section-network-feature">
        <Container>
          <Row md="12">
            <Col className="item-center mr-auto ml-auto" md="6">
              <img
                alt="..."
                src={require("assets/img/network/feature/1.png").default}
              />
            </Col>
            <Col className="mr-auto ml-auto network-feature" md="6">
              <div>
                <h3><strong>{t('network.feature.0.title')}</strong></h3>
                <h5 className="npy-gray">
                  {t('network.feature.0.desc')}
                </h5>
              </div>
            </Col>
          </Row>
          <Row md="12">
            <Col className="mr-auto ml-auto network-feature order-bottom" md="6">
              <div>
                <h3><strong>{t('network.feature.1.title')}</strong></h3>
                <h5 className="npy-gray">
                  {t('network.feature.1.desc')}
                </h5>
              </div>
            </Col>
            <Col className="item-center mr-auto ml-auto" md="6">
              <img
                alt="..."
                src={require("assets/img/network/feature/2.png").default}
              />
            </Col>
          </Row>
          <Row md="12">
            <Col className="item-center mr-auto ml-auto" md="6">
              <img
                alt="..."
                src={require("assets/img/network/feature/3.png").default}
              />
            </Col>
            <Col className="mr-auto ml-auto network-feature" md="6">
              <div>
                <h3><strong>{t('network.feature.2.title')}</strong></h3>
                <h5 className="npy-gray">
                  {t('network.feature.2.desc')}
                </h5>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Feature;
