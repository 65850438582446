import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

import { useTranslation } from "react-i18next";

function Comment() {
  const { t } = useTranslation();
  return (
    <>
      <div className="section">
        <Container>
          <Row>
            <h4 className="npy-black"><strong>{t('home.comment.title')}</strong></h4>
          </Row>
          <Row className="row-comment container-fluid" sm="12" md="12">
            <Col className="ml-auto mr-auto d-flex" sm="6" md="6">
              <Card 
                className="card-comment" 
                data-background-color="vsys" 
                tag="a" 
                href="https://medium.com/vsystems/neoply-joins-v-systems-as-one-of-the-supernodes-aa21496d4266" 
                target="_blank"
              >
                <CardBody>
                  <Row>
                      <img 
                        alt="..." 
                        src={require("assets/img/home/comment_vsys.png").default}
                        style={{ height:"48px" }}
                      />
                      <h4 style={{ paddingTop: "10px", paddingLeft: "10px" }}><strong>v.systems</strong></h4>
                  </Row>
                  <br/>
                  <Row>
                    <h5>{t('home.comment.vsystems')}</h5>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col className="ml-auto mr-auto d-flex" sm="6" md="6">
              <Card 
                className="card-comment" 
                data-background-color="iost" 
                tag="a" 
                href="https://medium.com/iost/korean-listed-company-leading-eos-block-producer-neoply-joins-iost-node-ecosystem-with-160m-votes-41f03c00b76" 
                target="_blank"
              >
                <CardBody>
                  <Row>
                      <img 
                        alt="..." 
                        src={require("assets/img/home/comment_iost.png").default}
                        style={{ height:"48px" }}
                      />
                      <h4 style={{ paddingTop:"10px", paddingLeft: "10px" }}><strong>IOST</strong></h4>
                  </Row>
                  <br/>
                  <Row>
                    <h5>{t('home.comment.iost')}</h5>
                  </Row>
                </CardBody>
              </Card>
            </Col> 
            <Col className="ml-auto mr-auto d-flex" sm="6" md="6">
              <Card 
                className="card-comment" 
                data-background-color="klaytn" 
                tag="a" 
                href="https://platum.kr/archives/118643" 
                target="_blank"
              >
                <CardBody>
                  <Row>
                      <img 
                        alt="..." 
                        src={require("assets/img/home/comment_klay.png").default}
                        style={{ height:"48px" }}
                      />
                      <h4 style={{ paddingTop:"10px", paddingLeft: "10px" }}><strong>Klaytn</strong></h4>
                  </Row>
                  <br/>
                  <Row>
                    <h5>{t('home.comment.klaytn')}</h5>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col className="ml-auto mr-auto d-flex" sm="6" md="6">
              <Card 
                className="card-comment" 
                data-background-color="ultra" 
                tag="a" 
                href="https://medium.com/ultra-io/eoseoul-is-joining-ultra-as-a-block-producer-91ccccd52bb9" 
                target="_blank"
              >
                <CardBody>
                  <Row>
                      <img 
                        alt="..." 
                        src={require("assets/img/home/comment_ultra.png").default}
                        style={{ height:"48px" }}
                      />
                      <h4 style={{ paddingTop:"10px", paddingLeft: "10px" }}><strong>ULTRA</strong></h4>
                  </Row>
                  <br/>
                  <Row>
                    <h5>{t('home.comment.ultra')}</h5>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Comment;
