import React from "react";

// reactstrap components
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

import { useTranslation } from "react-i18next";

import newsList from "../../assets/json/news.json"

function News() {
  const { i18n } = useTranslation();
  const [page, setPage] = React.useState(1);
  const pageSize = 3;
  return (
    <>
      <div className="section">
        <Container>
          <center>
            <h4 className="npy-red">Latest News</h4>
          </center>
          <br/>
          <Row className="container-fluid" sm="12" md="12">
          {newsList.map((news, index) =>
            (((page-1)*pageSize) < news.id) && (news.id <= (page*pageSize)) ? 
            <Col className="ml-auto mr-auto d-flex" sm="12" md="4" key={index}>
              <Card 
                className="card-news"
                tag="a" 
                href={i18n.language === "ko-KR" ? news.link : news.link_en} 
                target="_blank"
              >
                <CardHeader>
                  <img
                    alt="..."
                    src={require('assets/img'+news.img).default}
                  />
                </CardHeader>
                <CardBody>
                  <h6 className="npy-red">{news.category}</h6>
                  <h5><strong>{i18n.language === "ko-KR" ? news.title : news.title_en}</strong></h5>
                  <h6 className="npy-gray">{news.date}</h6>
                </CardBody>
              </Card>
            </Col>
            : null
          )}
          </Row>
          <Pagination>
                <PaginationItem className="arrow">
                  <PaginationLink
                    onClick={(e) => {e.preventDefault(); setPage(page!==1 ? page-1 : page)}}
                  >
                    &lt;
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={page===1 ? "active":""}>
                  <PaginationLink
                    onClick={(e) => {e.preventDefault(); setPage(1);}}
                  >
                    1
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={page===2 ? "active":""}>
                  <PaginationLink
                    onClick={(e) => {e.preventDefault(); setPage(2);}}
                  >
                    2
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className="arrow">
                  <PaginationLink
                    onClick={(e) => {e.preventDefault(); setPage(page!==2 ? page+1 : page)}}
                  >
                    &gt;
                  </PaginationLink>
                </PaginationItem>
            </Pagination>
            <br/>
        </Container>
      </div>
    </>
  );
}

export default News;
