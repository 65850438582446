import React from "react";

// reactstrap components
import { Row, Col, Container } from "reactstrap";

import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation();
  return (
    <>
      <div className="page-header page-header--after-bg">
        <div className="page-header-image"></div>
        <Container className="page-header-container">
          <Row className="main-banner" sm="12" md="12" lg="12">
            <Col className="main-banner-text" sm="12" md="12" lg="12">
              <div>
                <h1>
                  <strong>
                  {t('home.header.title').split('\n')
                    .map(c => {
                      return ( <> {c} <br/></>) 
                    })
                  }
                  </strong>
                </h1>
              </div>
            </Col>
            {/* <Col className="main-banner-img order-bottom" sm="12" md="6" lg="6">
              <div>
                <img
                  className="main-banner-img-row"
                  alt="..."
                  src={require("assets/img/home/banner_row.png").default}
                />
                <img
                  className="main-banner-img-row-md"
                  alt="..."
                  src={require("assets/img/home/banner_row_md.png").default}
                />
                <img
                  className="main-banner-img-col"
                  alt="..."
                  src={require("assets/img/home/banner_col.png").default}
                />
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Header;
