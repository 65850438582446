/*eslint-disable*/
import React from "react";
import { BsEnvelopeFill } from 'react-icons/bs';

// reactstrap components
import {
  Row,
  Col,
  Button,
  Container
} from "reactstrap";

import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Container>
          <Row className="contact" xs="12">
            <Button href="mailto:support@neoply.com">
              <h4>
                {t('btn_contact')}&nbsp;&nbsp;
                <BsEnvelopeFill style={{ marginBottom: "2px" }} />
              </h4>
            </Button>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Contact;
