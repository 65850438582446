import React from "react";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

import { useTranslation } from "react-i18next";
import PDF from "assets/neoply_isms.pdf";

function ISMS() {
  const { t } = useTranslation();
  return (
    <>
      <div className="section section-isms" data-background-color="lightgray">
        <Container>
          <Card
            className="card-isms"
            data-background-color="lightgray"
            tag="a"
            // href={PDF} // 23.06.12 isms 링크 삭제
            target="_blank"
          >
            <img alt="..." src={require("assets/img/home/isms.png").default} />

            <div className="isms-text">
              <h5>{t("home.isms.title")}</h5>
              <h6 className="npy-gray">{t("home.isms.range")}</h6>
              <h6 className="npy-gray">{t("home.isms.period")}</h6>
            </div>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default ISMS;
