import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

import { BsPlus } from 'react-icons/bs';

import { useTranslation } from "react-i18next";

function Guide({chain}) {
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  return (
    <>
      <div>
          <Container className="section-chain-guide">
            <Row>
              <Col>
                <h3 className="npy-red"><strong>{t('network.chain.guide.title')}</strong></h3>
              </Col>
            </Row>
            <Row sm="12" md="12" lg="12" style={{justifyContent:"space-between"}}>
              <Col sm="12" md="6" lg="5">
                <h4><strong>01. {t('network.chain.'+chain.name.toLowerCase()+'.guide.0.title')}</strong></h4>
                <h5 
                  className="npy-gray" 
                  dangerouslySetInnerHTML={{__html: t('network.chain.'+chain.name.toLowerCase()+'.guide.0.desc') }} 
                />
              </Col>
              <Col sm="12" md="6" lg="5">
                <h4><strong>02. {t('network.chain.'+chain.name.toLowerCase()+'.guide.1.title')}</strong></h4>
                <h5 
                  className="npy-gray" 
                  dangerouslySetInnerHTML={{__html: t('network.chain.'+chain.name.toLowerCase()+'.guide.1.desc') }} 
                />
              </Col>
            </Row>
            <Row sm="12" md="12" lg="12" style={{justifyContent:"space-between"}}>
              <Col sm="12" md="6" lg="5">
                <h4><strong>03. {t('network.chain.'+chain.name.toLowerCase()+'.guide.2.title')}</strong></h4>
                <h5 
                  className="npy-gray" 
                  dangerouslySetInnerHTML={{__html: t('network.chain.'+chain.name.toLowerCase()+'.guide.2.desc') }} 
                />
              </Col>
              <Col sm="12" md="6" lg="5">
                <h4><strong>04. {t('network.chain.'+chain.name.toLowerCase()+'.guide.3.title')}</strong></h4>
                <h5 
                  className="npy-gray" 
                  dangerouslySetInnerHTML={{__html: t('network.chain.'+chain.name.toLowerCase()+'.guide.3.desc') }} 
                />
              </Col>
            </Row>
            <Row className="item-center">
              <Col>
                <Button 
                  className="btn-chain-detail"
                  href={i18n.language === "ko-KR" ? chain.guide_link : chain.guide_link_en}
                  target="_blank"
                >
                  <h4><BsPlus/>&nbsp;&nbsp;{t('network.chain.guide.btn')}</h4>
                </Button>
              </Col>
            </Row>   
          </Container>
      </div>
    </>
  );
}

export default Guide;
