/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

import { FaArrowCircleUp } from 'react-icons/fa';

import { useTranslation } from "react-i18next";
 
function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <Row sm="12" md="12" lg="12">
            <Col sm="12" md="2" lg="2">
              <a href="/index">
                <img
                  alt="..."
                  src={require("assets/img/common/footer_logo.png").default}
                  className="img-footer"
                />
              </a>
            </Col>
            <Col className="footer-nav-1" sm="12" md="6" lg="7">
              <Row>
                <h6 className="npy-gray">{t('footer.intro')}</h6>
                <br/>
              </Row>
              <Row>
                <a className="mail-link" href="mailto:support@neoply.com">support@neoply.com</a>
                <br/>
              </Row>
            </Col>
            <Col className="footer-nav-2" sm="12" md="4" lg="3">
              <a href="https://www.facebook.com/NEOPLY" target="_blank">
                <img
                  alt="..."
                  src={require("assets/img/home/footer_fb.png").default}
                  style={{ marginRight: "15px", width: "30px" }}
                />
              </a>
              <a href="https://twitter.com/NEOPLY_KR" target="_blank">
                <img
                  alt="..."
                  src={require("assets/img/home/footer_tw.png").default}
                  style={{ marginRight: "15px", width: "30px" }}
                />
              </a>
              <a href="https://t.me/eoseoul" target="_blank">
                <img
                  alt="..."
                  src={require("assets/img/home/footer_tg.png").default}
                  style={{ width: "30px" }}
                />
              </a>
              <a 
                className="btn-top"
                onClick={(e) => {
                  window.scrollTo(0, 0);
                }}>
                Top <FaArrowCircleUp />
              </a>
            </Col>
          </Row>
          <br/><br/><br/>
          <Row className="justify-content-center">
            <div className="copyright">
              <h6 className="npy-gray">Copyright © 2021. NEOPLY All rights reserved.</h6>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
