import React from "react";

// reactstrap components
import { Row, Col, Container } from "reactstrap";

import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation();
  return (
    <>
      <div className="page-header aboutus-banner">
        <div className="page-header-image">
          <img className="left" alt="..." src={require("assets/img/aboutus/banner_left.png").default}/>
          <img className="right" alt="..." src={require("assets/img/aboutus/banner_right.png").default}/>
          <img className="banenr-sm" alt="..." src={require("assets/img/aboutus/banner_sm.png").default}/>
        </div>
        <Container>
          <Row className="aboutus-banner-row" md="12">
            <Col className="aboutus-banner-text" md="12">             
              <h4 className="npy-red"><strong>About NEOPLY</strong></h4>
              <h1 className="npy-black">
                <strong>
                  {t('aboutus.title').split('\n')
                    .map(c => {
                      return ( <> {c} <br/></>) 
                    })
                  }
                </strong>
              </h1>
              <h4 className="npy-gray">
                {t('aboutus.desc').split('\n')
                  .map(c => {
                    return ( <> {c} <br/></>) 
                  })
                }
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Header;
