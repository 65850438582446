import React from "react";

// core components
import Navigator from "components/common/Navigator.js";
import Header from "components/aboutus/Header.js";
import Feature from "components/aboutus/Feature.js";
import Project from "components/aboutus/project";
import Contact from "components/common/Contact.js";
import Footer from "components/common/Footer.js";

function AboutUs() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  return (
    <>
      <Navigator />
      <Header />
      <div className="wrapper section-aboutus">
        <Feature />
        <Project />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default AboutUs;
