import React from "react";

// core components
import EOSeoul from "components/aboutus/project/EOSeoul.js";
import NodeOperation from "components/aboutus/project/NodeOperation.js";
import Nblocks from "components/aboutus/project/Nblocks.js";
import Investment from "components/aboutus/project/Investment.js";

function Project() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  return (
    <>
      <div>
        <EOSeoul />
        <NodeOperation />
        <Nblocks />
        <Investment />
      </div>
    </>
  );
}

export default Project;
