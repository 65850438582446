import React from "react";

// core components
import Navigator from "components/common/Navigator.js";
import GoogleMaps from "components/contact/GoogleMaps.js";
import Footer from "components/common/Footer.js";

function Contact() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  return (
    <>
      <Navigator />
      <div className="wrapper" data-background-color="lightgray" >
        <GoogleMaps />
        <Footer />
      </div>
    </>
  );
}

export default Contact;
