import React from "react";
import { useLocation } from "react-router-dom"

// core components
import Navigator from "components/common/Navigator.js";
import Header from "components/home/Header.js";
import Contact from "components/common/Contact.js";
import Footer from "components/common/Footer.js";

// sections for this page
import Network from "components/home/Network.js";
import Comment from "components/home/Comment.js";
import AboutUs from "components/home/AboutUs.js";
import News from "components/home/News.js";
import ISMS from "components/home/ISMS.js";


function Home() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  React.useEffect(() => {
    setTimeout(() => {
      const hash = location.hash;
      const element = document.querySelector(hash);
      if (element && window) {
        const scrollHeight = element.scrollHeight;
        window.scrollTo(0, scrollHeight);
      }
    }, 0);
  }, []);
  return (
    <>
      <Navigator />
      <div className="wrapper">
        <Header />
        <div className="main">
          <Network />
          <Comment />
          <AboutUs />
          <News />
          <ISMS />
        </div>
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default Home;
