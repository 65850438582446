import React from "react";
import {
    Container,
    Row,
    Col,
} from "reactstrap";
import GoogleMapReact from 'google-map-react';

import { useTranslation } from "react-i18next";

const defaultProps = {
  center: {
    lat: 37.40014120364151, 
    lng: 127.10424192323367
  },
  zoom: 17
};

function GoogleMaps() {
  const { t } = useTranslation();

  const onGoogleApiLoaded = (map, maps) => {
      map.setOptions({ minZoom: 15, maxZoom: 19 });
  };

  return (
    <>
      <Container className="section-contact">
        <Row md="12">
          <Col md="4">
            <h2><strong>Contact Us</strong></h2>
          </Col>
          <Col md="8">
            <Row>
              <h5>{t('contact.title')}</h5>
            </Row>
            <Row>
              <h5><strong>{t('contact.sub.0.title')}</strong></h5>
            </Row>
            <Row>
              <a className="npy-red" href="mailto:support@neoply.com"><p><strong>support@neoply.com</strong></p></a>
            </Row>
            <Row>
              <h5><strong>{t('contact.sub.1.title')}</strong></h5>
            </Row>
            <Row>
              <p>{t('contact.sub.1.desc')}</p>
            </Row>
            <Row>
              <h5><strong>{t('contact.sub.2.title')}</strong></h5>
            </Row>
            <Row>
              <div style={{ height: '60vh', width: '100%', maxHeight: "400px" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={
                        {
                            key: 'AIzaSyBXHsANCCVzlJ3NR178zEdqt1XhR3X__TQ',
                            language: 'ko',
                            region: 'kr'
                        }
                    }
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
                  >
                  <img
                    alt="..."
                    style={{ width: "20px" }}
                    lat={37.40014120364151}
                    lng={127.10424192323367}
                    text="My Marker"
                    src={require("assets/img/contact/pin.png").default}
                  />
                  </GoogleMapReact>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default GoogleMaps;