import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
 
import langEn from 'lang/english';
import langKo from 'lang/korean';
 
const resource =  {
    'en-US': {
        translation: langEn
    },
    'ko-KR': {
        translation: langKo
    }
};

const defaultLng = sessionStorage.getItem('language') || navigator.language || 'en-US';

i18n
    .use(initReactI18next)
    .init({
        resources: resource,
        // 초기 설정 언어
        lng: defaultLng,
        fallbackLng: {
            'en-US': ['en-US'],
            'en': ['en-US'],
            'ko-KR': ['ko-KR'],
            'ko': ['ko-KR'],
            default: ['en-US']
        },
        debug: true,
        defaultNS: 'translation',
        ns: 'translation',
        keySeparator: '.',
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        }
    })
 
export default i18n;
